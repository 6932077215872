<template>
  <div class="header-box">
    <div class="header">
      <div
        style="height: 100%; display: flex"
        v-for="(item, index) in menuList"
        :key="item.name"
      >
        <div
          @click="clickHeader(item.link, index)"
          v-if="item.name != 'center'"
          :class="[active == index ? 'menu-active' : '', 'menu-box']"
        >
          <span>{{ item.name }}</span>
          <div v-if="active == index" class="tag-line"></div>
        </div>
        <div v-else class="center-box">
          <el-image
            class="center-img"
            :src="require('@/assets/home/header-logo.png')"
            lazy
          ></el-image>
        </div>
        <div v-if="index != menuList.length - 1" class="line">
          <img class="line" src="@/assets/home/line.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menuList: [
        {
          name: "首页",
          link: "/",
        },
        {
          name: "医养中心",
          link: "/bringUpCenter",
        },
        {
          name: "居家照护",
          link: "/homeCare",
        },
        {
          name: "接触跟踪",
          link: "/tracking",
        },
        {
          name: "center",
          link: "",
        },
        {
          name: "创新应用",
          link: "/innovation",
        },
        {
          name: "交流研究",
          link: "/communication",
        },
        {
          name: "关于我们",
          link: "/aboutUs",
        },
        {
          name: "产品中心",
          link: "/productCenter",
        },
      ],
      // active: 0,
    };
  },
  // mounted() {
  //   let act = sessionStorage.getItem("headerActive") || 0;
  //   this.active = act;
  // },
  methods: {
    clickHeader(link) {
      // sessionStorage.setItem("headerActive", i);
      // this.active = i;
      this.$router.push({ path: link });
    },
  },
};
</script>
<style lang="less" scoped>
.header-box {
  text-align: center;
  width: 100%;
  height: 92.19px;
  background-color: red;
  background: url("../../assets/home/header-bg.png") no-repeat center;
  background-size: 100% 100%;
  .header {
    width: 1200px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    .menu-box {
      width: 122.88px;
      height: 100%;
      line-height: 92px;
      font-size: 19px;
      color: white;
      position: relative;
      font-family: "CN-Regular";
      cursor: pointer;
      .tag-line {
        position: absolute;
        width: 64.97px;
        height: 1.09px;
        background: #1f3876;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 16.28px;
      }
    }
    .line {
      width: 2px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 68px;
      }
    }
    .menu-active {
      background: white;
      color: #1f3876;
    }
    .center-box {
      width: 204px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      .center-img {
        width: 200px;
        height: 85px;
      }
      .line {
        width: 2px;
        height: 71px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }
    }
  }
}
</style>
