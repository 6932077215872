import Vue from 'vue'
import VueRouter from "vue-router"

// 明确安装路由功能
Vue.use(VueRouter)

// 定义一些路由
const routes = [
    {
        path: '',
        name: 'home',
        component: () => import('@/views/home')
      },
      {
        path: '/bringUpCenter',
        name: 'bringUpCenter',
        component: () => import('@/views/bringUpCenter')
      },
      {
        path: '/tracking',
        name: 'tracking',
        component: () => import('@/views/tracking')
      },
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('@/views/aboutUs')
      },
      {
        path: '/innovation',
        name: 'innovation',
        component: () => import('@/views/innovation')
      },
      {
        path: '/productCenter',
        name: 'productCenter',
        component: () => import('@/views/productCenter')
      },
      {
        path: '/communication',
        name: 'communication',
        component: () => import('@/views/communication')
      },
      {
        path: '/homeCare',
        name: 'homeCare',
        component: () => import('@/views/homeCare')
      },
      {
        path: '/newsReport',
        name: 'newsReport',
        component: () => import('@/views/newsReport')
      },
      {
        path: '/industryResearch',
        name: 'industryResearch',
        component: () => import('@/views/industryResearch')
      },
      {
        path: '*',
        name: '404',
        component: () => import('@/error/404')
      }
  ]

//   创建路由实例并传递 `routes` 配置
const router = new VueRouter({
    base: process.env.BASE_URL,
    routes, // `routes: routes` 的缩写
    // 路由跳转之后默认回到顶部
    scrollBehavior () {
        return { x: 0, y: 0 }
      }
  })

export default router