<template>
  <div class="canvas-bubbling">
    <canvas ref="canvas"></canvas>
  </div>
</template>
<script>
var width,
  height,
  canvas,
  ctx,
  circles,
  animateHeader = true;

function initHeader() {
  console.log("window.innerWidth", window);
  width = window.innerWidth;
  height = window.innerHeight;
  // target = { x: 0, y: height };

  // largeHeader = document.getElementById('large-header');
  // largeHeader.style.height = height + 'px';

  canvas.width = width;
  canvas.height = height;
  ctx = canvas.getContext("2d");

  // create particles
  circles = [];
  for (var x = 0; x < width * 0.5; x++) {
    var c = new Circle();
    circles.push(c);
  }
  animate();
}

function addListeners() {
  window.addEventListener("scroll", scrollCheck);
  window.addEventListener("resize", resize);
}

function scrollCheck() {
  if (document.body.scrollTop > height) animateHeader = false;
  else animateHeader = true;
}

function resize() {
  width = window.innerWidth;
  height = window.innerHeight;
  canvas.width = width;
  canvas.height = height;
}

function animate() {
  if (animateHeader) {
    ctx.clearRect(0, 0, width, height);
    for (var i in circles) {
      circles[i].draw();
    }
  }
  requestAnimationFrame(animate);
}

// Canvas manipulation
function Circle() {
  var _this = this;

  // constructor
  (function () {
    _this.pos = {};
    init();
  })();

  function init() {
    _this.pos.x = Math.random() * width;
    _this.pos.y = height + Math.random() * 200;
    _this.alpha = 0.1 + Math.random() * 0.3;
    _this.scale = 0.1 + Math.random() * 0.35;
    _this.velocity = Math.random();
  }

  this.draw = function () {
    if (_this.alpha <= 0) {
      init();
    }
    _this.pos.y -= _this.velocity;
    _this.alpha -= 0.0005;
    ctx.beginPath();
    ctx.arc(_this.pos.x, _this.pos.y, _this.scale * 10, 0, 2 * Math.PI, false);
    ctx.fillStyle = "rgba(68, 68, 68," + _this.alpha + ")";
    ctx.fill();
  };
}

export default {
  mounted() {
    canvas = this.$refs.canvas;

    initHeader();
    addListeners();
  },
};
</script>
<style lang="less" scoped>
.canvas-bubbling {
  width: 100%;
  height: 100%;
}
</style>
