
// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.
// 为了方便起见，全局注册所有基本组件，因为它们将被非常频繁地使用。
// 组件使用其文件名的pascalcase版本进行注册。

import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
// require.context
// 您可以使用该require.context()函数创建自己的上下文。
// 它允许您传入要搜索的目录、指示是否也应搜索子目录的标志以及匹配文件的正则表达式


const requireComponent = require.context(
    // Look for files in the current directory
    // 在当前目录中查找文件
    '.',
    // Do not look in subdirectories
    // 是否在子目录中查找
    true,
    // Only include "_base-" prefixed .vue files
    // 查找只包含“_base-”前缀的.vue文件
    //   /_base-[\w-]+\.vue$/
    /\.vue$/
)

// For each matching file name...
// 处理每个匹配的文件名
requireComponent.keys().forEach((fileName) => {
    // Get the component config
    // 获取组件配置
    const componentConfig = requireComponent(fileName)
    // Get the PascalCase version of the component name
    // 获取组件名的PascalCase版本
    const componentName = fileName
        // Remove the "./_" from the beginning
        // 删除开头的"./_"字符
        // .replace(/^\.\/_/, '')
        .replace(/^\.\//, '')
        // Remove the file extension from the end
        // 删除末尾的文件扩展名
        // .replace(/\.\w+$/, '')
        .replace(/\/index\.\w+$/, '')
        // Split up kebabs
        .split('-')
        // Upper case
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        // Concatenated
        .join('')


    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
})



// Globally register all base components for convenience, because they will be used very frequently. Components are registered using the PascalCased version of their file name.