import Vue from 'vue'
// 引入ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from "./router/index"
import './assets/fonts/font.css'



import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import 'lib-flexible'
import myMinix from './myMixin';
Vue.mixin(myMinix);

// 全局注册 ElementUI
Vue.use(ElementUI);

// 全局注册组件
import "@/components/index"

/**关闭生产环境下的告警提示 */
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
