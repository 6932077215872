export default {
    computed:{
        pxToRem:function(){
            return(px)=>{
                // console.log(px)
                if(Array.isArray(px)){
                    let str = ""
                    px.forEach(p=>{
                        str+=(p/192).toFixed(8)+"rem "
                    })
                    return str
                }else{
                    return `${(px/192).toFixed(8)}rem`
                }
                
            }
        }
    }
}