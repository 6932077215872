<template>
  <div id="app">
    <!-- <Header></Header> -->
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "./views/components/Footer/index";
// import Header from "./views/components/Header/index";
export default {
  name: "App",
  components: { Footer },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
  /* width: 100%;
  height: 100%; */
  /* min-width: 1300px; */
  // transition: all 0.8s;
}
* {
  padding: 0;
  margin: 0;
}
.w-20 {
  width: 20px !important;
}
.w-23 {
  width: 23px !important;
}
.w-255 {
  width: 255px !important;
}
.w-394 {
  width: 394px !important;
}
.w-405 {
  width: 405px !important;
}
.w-486 {
  width: 486px !important;
}
.w-635 {
  width: 635px !important;
}
.w-655 {
  width: 655px !important;
}
.w-753 {
  width: 753px !important;
}
.w-785 {
  width: 785px !important;
}
.h-20 {
  height: 20px !important;
}
.h-23 {
  height: 23px !important;
}
.h-505 {
  height: 505px !important;
}
.h-615 {
  height: 615px !important;
}
.h-696 {
  height: 696px !important;
}
.h-702 {
  height: 702px !important;
}
.m-t-f5 {
  margin-top: -5px !important;
}
.m-t-7 {
  margin-top: 7px !important;
}
.m-t-8 {
  margin-top: 8px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-14 {
  margin-top: 14px !important;
}
.m-t-16 {
  margin-top: 16px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-21 {
  margin-top: 21px !important;
}
.m-t-25 {
  margin-top: 25px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-36 {
  margin-top: 36px !important;
}
.m-t-49 {
  margin-top: 49px !important;
}
.m-t-75 {
  margin-top: 75px !important;
}
.m-t-148 {
  margin-top: 148px !important;
}
.m-t-180 {
  margin-top: 180px !important;
}
.m-t-217 {
  margin-top: 217px !important;
}
.m-l-50 {
  margin-left: 50px !important;
}
.m-l-68 {
  margin-left: 68px !important;
}
.m-l-69 {
  margin-left: 69px !important;
}
.m-r-20 {
  margin-left: 20px !important;
}
.m-r-25 {
  margin-right: 25px !important;
}
.m-r-48 {
  margin-right: 48px !important;
}
.m-r-50 {
  margin-right: 50px !important;
}
.p-t-114 {
  padding-top: 114px !important;
}
.p-t-105 {
  padding-top: 105px !important;
}
</style>
